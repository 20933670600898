<template>
  <div>
    <Header
      :details="{
        title: 'Users',
        back: { name: 'users' },
        actions: false,
      }"
    />

    <div class="client" id="main-container">
      <div id="client-form">
        <div class="_client-infos">
          <div class="u-flex u-flex-justify-c-flex-end u-flex-align-i-center">
            <div class="flex flex-align-items-center u-mr-30 u-mb-20">
              <span>Active</span>
              <ToggleButton v-model="user.active" />
            </div>
            <Select
              v-if="!this.isNew"
              class="u-mb-20"
              :options="actions"
              @input="onActionChange"
              :value="selectedAction"
            ></Select>
          </div>
          <div class="grid">
            <div class="grid__i--6-12">
              <div class="notice u-mb-20" v-if="errors.length">
                <ul>
                  <li v-for="error in errors" :key="error">- {{ error }}</li>
                </ul>
              </div>
              <div class="box-container">
                <div class="grid">
                  <div class="grid__i--12-12 input-box u-mb-10">
                    <label for="email">User login ( email )</label>
                    <input type="email" id="email" v-model="user.email" />
                  </div>

                  <div class="grid__i--12-12 input-box u-mb-10">
                    <label for="password"
                      >New password ( at least 7 characters, one number and one special character. )</label
                    >
                    <input
                      name="password"
                      id="password"
                      type="password"
                      autocomplete="old-password"
                      v-model="user.password"
                    />
                  </div>

                  <div class="grid__i--12-12 input-box u-mb-10" v-if="user.password && user.password.length">
                    <label for="password_confirmation">Confirm new password</label>
                    <input
                      name="password_confirmation"
                      id="password_confirmation"
                      autocomplete="new-password"
                      type="password"
                      v-model="user.password_confirmation"
                    />
                  </div>

                  <div class="grid__i--12-12 input-box">
                    <label for="note">Note</label>
                    <textarea name="note" id="note" type="text" v-model="user.note"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid__i--6-12">
              <div class="box-container">
                <div class="contact-card">
                  <div class="box flex">
                    <label>{{ $t("overall.first_name") }}</label>
                    <input type="text" v-model="user.first_name" />
                  </div>

                  <div class="box flex">
                    <label>{{ $t("overall.last_name") }}</label>
                    <input type="text" v-model="user.last_name" />
                  </div>

                  <div class="box flex">
                    <label>Access</label>
                    <Select v-model="user.role.id" :options="rolesOptions" />
                  </div>

                  <div class="box flex">
                    <label>{{ $t("overall.phone") }}</label>
                    <input type="text" v-model="user.phone" />
                  </div>

                  <div class="box flex hide">
                    <label>{{ $t("clients.pref_language") }}</label>
                    <input type="text" v-model="user.language" />
                  </div>

                  <div class="address hide">
                    <div class="box full-box">
                      <label>{{ $t("overall.note") }}</label>
                      <Textarea v-model="user.note" :is-counter="true" :max="400" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="actions-bar">
        <template v-if="!loading">
          <button class="cancel">{{ $t("overall.cancel") }}</button>
          <button class="cta save fixed-button" @click="save">
            <span class="txt">{{ $t("overall.save") }}</span>
          </button>
        </template>
        <span class="loader" v-else></span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";

const ACTION_NOTHING = "nothing";
const ACTION_DELETE = "delete";

export default Vue.extend({
  name: "EditUser",
  data() {
    return {
      actions: [
        { name: "Realize an action", value: ACTION_NOTHING },
        { name: "Delete user", value: ACTION_DELETE },
      ],
      selectedAction: ACTION_NOTHING,
      loading: false,
      currentDeal: null,
      roles: [],
      errors: [],
      isNew: false,
      user: {
        id: null,
        token: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        note: "",
        role: { id: 1 },
        password: "",
        password_confirmation: "",
        active: false,
      },
    };
  },
  computed: {
    rolesOptions() {
      if (this.roles.length) {
        return this.roles.map((role) => {
          return { name: role.name, value: role.id };
        });
      }
      return [];
    },
  },
  methods: {
    async onActionChange(value) {
      if (this.selectedAction === value) {
        return;
      }
      this.selectedAction = value;
      if (value === ACTION_DELETE) {
        const confirm = window.confirm("Are you sure you want to delete this user ?");
        if (confirm) {
          // Get all IDs ( token )
          const ids = [this.user.token];
          // Delete in backend
          const response = await this.$axios.delete("api/users", { data: { ids } });
          if (response.status === 200) {
            console.log("[Action] delete - success", response);
            // redirect to list
            await this.$router.push({ name: "users" });
          } else {
            console.log("[Action] delete - error", response);
          }
          // Trick for <Select> component to trigger change
          const timer = setTimeout(() => {
            this.selectedAction = ACTION_NOTHING;
            clearTimeout(timer);
          }, 10);
        } else {
          console.log("[Action] delete - user refused");
        }
      }
    },
    async fetchRoles() {
      const response = await this.$axios.get("/api/roles");
      this.roles = response.data;
    },
    async save() {
      this.loading = true;

      this.errors = [];

      let status = "success";
      let message = "Success";

      try {
        const user = { ...this.user, role: this.user.role.id };
        const response = await this.$axios.post("/api/users", {
          user,
        });
        console.log("Update response", response);
        if (this.isNew) {
          this.$router.push({ name: "users" });
        }
      } catch (error) {
        let errors = [];
        if (error.response.status === 422) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            errors = [...errors, ...value];
          }
          this.errors = errors;
          message = "Error while saving the user.";
          status = "error";
        } else {
          message = "Undefined error, see logs.";
          status = "error";
        }
      }

      this.loading = false;

      notifBus.$emit("show", {
        status,
        message,
      });
    },
  },
  created() {
    this.isNew = !this.$route.params.token;
    this.fetchRoles();
    if (!this.isNew) {
      this.$axios.get("/api/users/" + this.$route.params.token).then((res) => {
        this.user = {
          ...res.data,
          ...{
            password: "",
            password_confirmation: "",
          },
        };
      });
    }
  },
});
</script>
